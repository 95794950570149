import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/me/aaa/github/personalsite/src/components/post_template.js";
import ImageSource from "src/components/image";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Waves`}</h1>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Waves_(airline)"
      }}>{`Waves`}</a>{` was a web and mobile app for an air taxi service based in `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Guernsey"
      }}>{`Guernsey`}</a>{` in the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Channel_Islands"
      }}>{`Channel Islands`}</a>{` (off the French coast of Normandy).`}</p>
    <br />
    <p><strong parentName="p">{`Frontend (web)`}</strong></p>
    <ul>
      <li parentName="ul">{`React.js`}</li>
      <li parentName="ul">{`styled-components (CSS-in-JS)`}</li>
      <li parentName="ul">{`Redux`}</li>
    </ul>
    <p><strong parentName="p">{`Backend`}</strong></p>
    <ul>
      <li parentName="ul">{`Node.js`}</li>
      <li parentName="ul">{`express`}</li>
      <li parentName="ul">{`MySQL`}</li>
      <li parentName="ul">{`Google Cloud Platform`}</li>
      <li parentName="ul">{`Stripe`}</li>
    </ul>
    <br />
    <hr />
    <br />
    <ImageSource src="waves/landing.png" alt="Landing page" mdxType="ImageSource" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      